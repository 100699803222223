// Import action constants
import {
	GET_ME,
} from 'actions/User';

// Define the initial state
const INITIAL_STATE = {
	me: null,
};

const UserReducer = (state = INITIAL_STATE, action) => {
	const { type, payload } = action;

	switch ( type ) {
		case GET_ME:
			return { ...state, me: payload };

		default:
			return state;
	}
}

export default UserReducer;
