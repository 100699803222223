/* Import dependencies */
import Axios from 'axios';

/* Define action types */
export const GET_LESSONS = 'LESSONS_GET_LESSONS';
export const SEARCH_LESSONS = 'LESSONS_SEARCH_LESSONS';
export const RESET_SEARCH_RESULTS = 'LESSONS_RESET_SEARCH_RESULTS';

function fetchLessons(page = 1) {
	return new Promise(resolve => {
		Axios.get(`https://digitalalektioner.se/wp-json/wp/v2/lessons?per_page=100&page=${page}`, {
			withCredentials: true,
		}).then((response) => {
			resolve(response);
		});
	});
}

export const getLessons = () => ({
	type: GET_LESSONS,
	payload: new Promise((resolve) => {
		let lessons = [];
		let page = 1;

		fetchLessons(page).then(async (response) => {
			lessons = [...lessons, ...response.data];

			if (page < response.headers['x-wp-totalpages']) {
				for(let i = 2; i <= response.headers['x-wp-totalpages'];) {
					let { data } = await fetchLessons(i);
					lessons = [...lessons, ...data];
					i++;
				}
			}

			resolve(lessons);
		});
	}),
});

export const searchLessons = (query) => ({
	type: SEARCH_LESSONS,
	payload: new Promise((resolve) => {
		Axios.get(`https://digitalalektioner.se/wp-json/wp/v2/search?per_page=100&subtype=lessons&search=${query}&context=view`, {
			withCredentials: true,
		}).then((response) => {
			resolve(response.data);
		});
	}),
});

export const resetSearchResults = () => ({
	type: RESET_SEARCH_RESULTS,
	payload: true,
});