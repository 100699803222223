import React, { Component } from 'react';
import msal, {
	setUser,
	loginRequest,
	errors,
	onMsalLoginRequired,
} from 'libs/msal';

import { app, authentication } from '@microsoft/teams-js';

import AuthenticationPopup from 'authentication/AuthenticationPopup';
import AuthenticationPage from './AuthenticationPage';
import GenerateToken from 'Teams/GenerateToken';
import ConfigurationPage from 'Teams/ConfigurationPage';

class AuthenticationProvider extends Component {

	static defaultProps = {
		loginComponent: null,
	}

	constructor(props) {
		super(props);

		let authenticated = msal.getAllAccounts().length > 0;
		let interactive = false;

		if (localStorage.getItem('dl-app-logged-out') == 'yes') {
			authenticated = false;
			interactive = true;
		}

		this.state = {
			authenticated,
			loading: true,
			interactive,
			error: null,
		};
	}

	inIframe() {
		try {
			return window.self !== window.top || window.navigator.userAgent.indexOf('TeamsMobile') > -1;
		} catch (e) {
			return true;
		}
	}

	allStorage = () => {
		var archive = [],
			keys = Object.keys(localStorage);

		for (let i = 0; i < keys.length; i++) {
			let key = keys[i];
			archive.push(key + '=' + localStorage.getItem(key));
		}

		return archive;
	}

	runLogin = () => {
		if (localStorage.getItem('dl-teams-auth-progress') != null) {
			return;
		}

		localStorage.setItem('dl-teams-auth-progress', 'login');

		authentication.authenticate({
			url: '/login',
		}).then((response) => {
			localStorage.removeItem('dl-teams-auth-progress');

			if (msal.getAllAccounts().length > 0 && response != null) {
				setUser(response.account.username);

				this.setState({ authenticated: true, loading: false });
			} else {
				this.setState({ authenticated: false, loading: false });
			}
		}).catch((error) => {
			localStorage.removeItem('dl-teams-auth-progress');

			this.setState({ authenticated: false, loading: false });
		});
	}

	componentDidMount = () => {
		if (window.location.hash.indexOf('code') > -1 && window.location.pathname != '/login') {
			this.setState({ loading: true });
			msal.handleRedirectPromise().then((response) => {
				if (response != null) {
					setUser(response.account.username);

					if (msal.getAllAccounts().length > 0) {
						this.setState({ authenticated: true, loading: false });
					}
				} else {
					this.setState({ authenticated: false, loading: false });
				}
			}).catch((reason) => {
				this.setState({ authenticated: false, loading: false });

				if (reason == null) {
					return false;
				}

				if (reason instanceof errors.AuthError) {
					msal.logoutRedirect();
				}
			});

			return true;
		}

		if (!this.state.authenticated && localStorage.getItem('dl-app-logged-out') != 'yes') {
			if (window.location.pathname != '/login') {
				app.getContext().then((context) => {
					let request = loginRequest;
					request.loginHint = context.user.loginHint;

					msal.ssoSilent(request).then((response) => {
						if (response != null) {
							this.onLandingPageLogin(response);
						} else {
							this.setState({ loading: false });
						}
					}).catch((reason) => {
						this.setState({ loading: false, authenticated: false });
					})
				});
			}
		} else {
			this.setState({ loading: false });
		}

		if (localStorage.getItem('dl-teams-auth-progress') != null) {
			localStorage.removeItem('dl-teams-auth-progress');
		}

		onMsalLoginRequired((event) => {
			this.setState({ loading: false, authenticated: false, interactive: true });
		});
	}

	onLandingPageLogin = (response) => {
		setUser(response.account.username);

		localStorage.removeItem('dl-app-logged-out');

		this.setState({ authenticated: true, loading: false, interactive: false });
	}

	render() {
		if (window.location.pathname == '/teams/configure' || window.location.pathname == '/configure') {
			return <ConfigurationPage />
		}

		if (window.location.pathname == '/GenerateToken') {
			return <GenerateToken />
		}

		if (window.location.pathname == '/login') {
			return <AuthenticationPopup />
		}

		if (this.state.authenticated) {
			return this.props.children;
		}

		if (this.state.loading) {
			return <div></div>
		}

		return <AuthenticationPage
			interactive={this.state.interactive}
			onLogin={this.onLandingPageLogin}
		/>
	}

}

export default AuthenticationProvider;