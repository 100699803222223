import React, { Component } from 'react';
import { connect } from 'react-redux';

/* Import resources */
import './Style.css';

class Grade extends Component {
	getGradeName = (id) => {
		if (id == null) {
			return null;
		}

		const { grades } = this.props;

		if (grades != null) {
			const grade = grades.find(function(grade) {
				return grade.id == id;
			});

			if (grade != null) {
				return grade.name;
			}
		}

		return null;
	}

	render() {
		const { id } = this.props;

		if (id) {
			return(
				<div key={`grade-tag-${id}`} className="grade">
					{this.getGradeName(id)}
				</div>
			);
		} else {
			return <div />;
		}
	}
}

function mapStateToProps(state) {
	return {
		grades: state.Meta.grades,
	};
};

export default connect(mapStateToProps)(Grade);