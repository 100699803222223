import Axios from 'axios';
import { http } from 'libs/msal';
import { API_URL } from '../AppContainer/';

const q = require('q');

export const Helper = {
	fetchMediaIfNeeded: (activeLesson) => {
		const deferred = q.defer();

		if (activeLesson.featured_media !== null && activeLesson.featured_media > 0) {
			Axios.get(`https://digitalalektioner.se/wp-json/wp/v2/media/${activeLesson.featured_media}`, {
				withCredentials: true,
			}).then((media) => {
				const image = media.data;
				activeLesson.image_link = image.guid.rendered;
				activeLesson.image_title = image.title.rendered;

				deferred.resolve(activeLesson);
			});
		} else {
			deferred.reject(0);
		}

		return deferred.promise;
	},
	fetchOrCreateSection: (notebook, title) => {
		const deferred = q.defer();

		http.get(notebook.sectionsUrl).then((response) => {
			let appSection = null;
			let parsedTitle = title.replace(/[^a-z0-9 åäö?!]/ig, '');;

			if (parsedTitle.length > 45) {
				parsedTitle = parsedTitle.substring(0, 45);
			}

			// Save the correct section object in the section variable
			// for later use
			response.data.value.map(function (sec, i) {
				if (sec.displayName === parsedTitle) {
					appSection = sec;
				}

				return appSection;
			});

			if (appSection == null) {
				// Create a new section for this Notebook if new need to
				http({
					url: notebook.sectionsUrl,
					method: 'post',
					data: JSON.stringify({
						'displayName': parsedTitle,
					}),
					headers: {
						'Content-Type': 'application/json',
						'Accept': 'application/json',
					},
				}).then(function (createdSection) {
					deferred.resolve(createdSection.data);
				});
			} else {
				deferred.resolve(appSection);
			}
		});

		return deferred.promise;
	},
	fetchOrCreateNotebook: (group_id) => {
		const deferred = q.defer();

		http.get(API_URL + 'groups/' + group_id + '/onenote/notebooks')
			.then((notebooksResponse) => {
				// Define variables
				let notebooks = notebooksResponse.data.value;
				let notebook = null;

				// Look for the correct NoteBook instance and save it in a separate variable
				notebooks.map(function (book, i) {
					if (book.displayName === 'Digitala Lektioner') {
						notebook = book;
					}

					return notebook;
				});

				if (notebook !== null) {
					// Clear the data from the API
					// As we no longer need it
					notebooks = [];

					// Resolve notebook
					deferred.resolve(notebook);
				} else {
					// No notebook could be found. Create it before proceeding
					http({
						method: 'post',
						url: API_URL + 'groups/' + group_id + '/onenote/notebooks',
						data: JSON.stringify({
							'displayName': 'Digitala Lektioner',
						}),
						headers: {
							'Content-Type': 'application/json',
							'Accept': 'application/json',
						},
					}).then(function (createdNoteBook) {
						// Save the result data in the notebook variable
						// as it gives you a notebook object as response
						deferred.resolve(createdNoteBook.data);
					});
				}
			});

		return deferred.promise;
	},
	stripHTML: (html) => {
		var tmp = document.createElement('div');
		tmp.innerHTML = html;
		return tmp.textContent || tmp.innerText || "";
	},
	parseLinksInContent: (html) => {
		var content = document.createElement('div');
		content.innerHTML = html;

		var links = content.getElementsByTagName('a');

		for (var c = 0; c < links.length; c++) {
			links[c].target = '_blank';
		}

		return content.innerHTML;
	},
	handleIframeInContent: (content) => {
		var html = document.createElement("html");
		html.innerHTML = content;
		var iframes = html.getElementsByTagName('iframe');

		var srcList = [];
		for (var i = 0; i < iframes.length; i++) {
			srcList.push(new URL(iframes[i].src));
		}

		if (srcList.length > 0) {
			var linkWrapper = document.createElement('div');
			linkWrapper.appendChild(document.createElement('br'));
			linkWrapper.appendChild(document.createElement('br'));
			linkWrapper.appendChild(document.createTextNode('Innehåll bifogat i uppgiften\n'));

			var list = document.createElement('ul');
			srcList.map(link => {
				var listItem = document.createElement('li');
				let linkName = link.hostname.replace('www.', '');
				linkName = linkName.replace('https://', '');
				linkName = linkName.replace('http://', '');

				listItem.appendChild(document.createTextNode(linkName + ': '));
				var a = document.createElement('a');
				a.appendChild(document.createTextNode('Gå till länk'));
				a.href = link.href;

				listItem.appendChild(a);
				list.appendChild(listItem);

				return true;
			});

			linkWrapper.appendChild(list);
			html.children[1].appendChild(linkWrapper);
		}

		var body = html.getElementsByTagName('body');
		return body[0].innerHTML;
	},
	fixImages: (content) => {
		var html       = document.createElement('html');
		html.innerHTML = content;
		var images     = html.getElementsByTagName('img');

		if (images.length > 0) {
			for (var c = 0; c < images.length; c++) {
				images[c].style.maxWidth = '720px';
			}
		}

		var body = html.getElementsByTagName('body');
		return body[0].innerHTML;
	},
};
