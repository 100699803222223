import React, { Component } from 'react';

class SearchForm extends Component {

	constructor(props) {
		super(props);

		this.state = {
			query: '',
		};

		this.filterRef = React.createRef();
	}

	onInputChange = (event) => {
		this.setState({ query: event.target.value });
	}

	submit = (event) => {
		event.preventDefault();

		this.filterRef.current.blur();

		if (this.props.onSubmit != null) {
			this.props.onSubmit(this.state);
		}
	}

	render() {
		return(
			<form onSubmit={this.submit}>
				<div className="search-input-container">
					<input
						placeholder="Sök efter lektion"
						type="text"
						name="query"
						onChange={this.onInputChange}
						ref={this.filterRef}
					/>

					<button type="submit" className="submit-button">
						Sök
					</button>
				</div>
			</form>
		)
	}

}

export default SearchForm;