import React, { Component } from 'react';

class Logout extends Component {

	componentDidMount = () => {
		localStorage.clear();
		localStorage.setItem('dl-app-logged-out', 'yes');
		window.location = '/';
	}

	render() {
		return(
			<div>
				Loggar ut...
			</div>
		);
	}
}

export default Logout;
