import React, { Component } from 'react';
import { modal as modalStyles } from 'constants/styles';
import Modal from 'react-modal';

import Button from 'UI/Button';

class SavedPartModal extends Component {
	render() {
		const {
			modalToggle,
			modalIsOpen,
			classNotebook,
		} = this.props;

		return(
			<Modal
				onRequestClose={modalToggle}
				contentLabel=""
				shouldCloseOnOverlayClick
				isOpen={modalIsOpen}
				style={modalStyles}
			>
				<div className="single-lesson">
					<div className="lesson-content" style={{ padding: '2rem 1rem', marginBottom: 0 }}>
						<div style={{ textAlign: 'center' }}>
							<h2 style={{ paddingBottom: '1rem' }}>Lektionen är sparad!</h2>
						</div>

						<div className="App-Container">
							<p>
								Lektionen har nu sparats som ett avsnitt i din Klassanteckningsbok under "Innehållsbibliotek".
								Om du vill använda lektionen som del av en uppgift, kan du skapa en uppgift och inkludera sidan från Klassanteckningsboken.
								Du kan också innan du gör det bearbeta och dela upp materialet så det passar ditt syfte.
							</p>
						</div>

						{classNotebook != null ?
							<div className="App-Container">
								Det sparade materialet hittar du i fliken Klassanteckningsboken eller öppna i en OneNote klient:<br />
								<a target="_blank" style={{color: 'black', display: 'block', marginTop: '0.4rem'}} href={classNotebook.links.oneNoteClientUrl.href} without rel="noreferrer">
									Öppna klassanteckningsbok i OneNote appen
								</a>

								<a target="_blank" style={{ display: 'block', marginTop: '0.4rem', color: 'black' }} href={classNotebook.links.oneNoteWebUrl.href} without rel="noreferrer">
									Eller öppna OneNote i webbläsaren
								</a>
							</div>
						: null}

						<div className="App-Container" style={{padding: '1rem 0', marginTop: '1rem', textAlign: 'center'}}>
							<Button onClick={modalToggle}>
								Okej! Jag förstår
							</Button>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

export default SavedPartModal;