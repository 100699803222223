// Import action constants
import {
	GET_LESSONS,
	SEARCH_LESSONS,
	RESET_SEARCH_RESULTS,
} from 'actions/Lessons';

// Define the initial state
const INITIAL_STATE = {
	lessons: [],
	searchResults: [],
};

const LessonsReducer = (state = INITIAL_STATE, action) => {
	const { type } = action;

	switch (type) {
		case GET_LESSONS:
			return { ...state, lessons: action.payload };

		case SEARCH_LESSONS:
			return { ...state, searchResults: action.payload };

		case RESET_SEARCH_RESULTS:
			return { ...state, searchResults: INITIAL_STATE.searchResults };

		default:
			return state;
	}
}

export default LessonsReducer;
