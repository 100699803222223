
import { http } from 'libs/msal';
import { API_URL } from '../containers/AppContainer';

/* Define action types */
export const GET_ME = 'USER_GET_ME';

/* Actions */
export const getMe = () => ({
	type: GET_ME,
	payload: new Promise(resolve => {
		http.get(API_URL + 'me')
		.then(response => {
			resolve(response.data);
		});
	}),
});
