import React from 'react';

const Chevron = props => (
	<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 30 30" style={{enableBackground: 'new 0 0 30 30' }}>
		<path style={{
				fill: 'none',
				stroke: '#fff',
				strokeWidth: 2,
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
				strokeMiterlimit: 10,
			}}
			d="M20,12.7l-5,4.5l-5-4.5"
		/>
	</svg>
);

export default Chevron;