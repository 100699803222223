/* Import dependencies */
import Axios from 'axios';

/* Define action types */
export const GET_SUBJECT_MATTERS = 'META_GET_SUBJECT_MATTERS';
export const GET_SCHOOL_SUBJECTS = 'META_GET_SCHOOL_SUBJECTS';
export const GET_GRADES          = 'META_GET_GRADES';

/* Actions */
export const getSubjectMatters = () => ({
	type: GET_SUBJECT_MATTERS,
	payload: new Promise( resolve => {
		Axios.get('https://digitalalektioner.se/wp-json/wp/v2/amnesomrade/', {
			withCredentials: true,
		}).then((response) => {
			let { data } = response;
			resolve(data);
		});
	}),
});

export const getSchoolSubjects = () => ({
	type: GET_SCHOOL_SUBJECTS,
	payload: new Promise( resolve => {
		Axios.get('https://digitalalektioner.se/wp-json/wp/v2/skolamne/', {
			withCredentials: true,
		}).then((response) => {
			let { data } = response;
			resolve(data);
		});
	}),
});

export const getGrades = () => ({
	type: GET_GRADES,
	payload: new Promise(resolve => {
		Axios.get('https://digitalalektioner.se/wp-json/wp/v2/arskurs', {
			withCredentials: true,
		}).then((response) => {
			let { data } = response;
			resolve(data);
		});
	}),
});