import React, { Component } from 'react';
import { pages } from "@microsoft/teams-js";
import { appInsights } from 'libs/tracking';

class RemovalPage extends Component {
	componentDidMount = () => {
		pages.config.registerOnRemoveHandler(function (removeEvent) {
			removeEvent.notifySuccess();
		});

		appInsights.trackEvent({ name: 'App removal' });

		pages.config.setValidityState(true);
	}

	render() {
		return (
			<div>
				Dina sparade lektioner kommer inte tas bort.<br />
			</div>
		);
	}
}

export default RemovalPage;