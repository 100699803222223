import React from 'react';

export default function Button({ children, onClick, style }) {
	return(
		<div style={{
			cursor: 'pointer',
			display: 'inline-block',
			color: 'white',
			padding: '0.8rem',
			backgroundColor: '#3284bf',
			fontWeight: 'bold',
			fontSize: '0.85rem',
			textAlign: 'center',
			minWidth: '8.25rem',
			...style
		}}
		onClick={ onClick }>
			{ children }
		</div>
	);
}
