import React, { Component } from 'react';
import { authentication } from '@microsoft/teams-js';
import msal from 'libs/msal';

class AuthenticationPopup extends Component {

	componentDidMount = () => {
		if (window.location.hash.indexOf('code') > -1) {
			msal.handleRedirectPromise().then((response) => {
				if (response != null) {
					authentication.notifySuccess(response);
				} else {
					authentication.notifyFailure('');
				}
			})
				.catch((reason) => {
					authentication.notifyFailure(reason);
				});

			return true;
		}

		let request = JSON.parse(localStorage.getItem('dl-teams-auth-request'));
		request.redirectUri = window.location.origin + '/GenerateToken';

		msal.acquireTokenRedirect(request);
	}

	render() {
		return(
			<div className="App-Container"></div>
		);
	}

}

export default AuthenticationPopup;
