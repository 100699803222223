import React, { Component } from 'react';
import msal from 'libs/msal';
import { authentication, app } from '@microsoft/teams-js';

import Button from '../UI/Button';

class AuthenticationPage extends Component {

	constructor(props) {
		super(props);

		this.state = {
			error: null,
		};
	}

	runLogin = () => {
		if (localStorage.getItem('dl-teams-auth-progress') != null) {
			return;
		}

		localStorage.setItem('dl-teams-auth-progress', 'login');

		let url = '/login';
		if (this.props.interactive || this.state.error || window.location.pathname.indexOf('configure') > -1) {
			url += '?interactive=yes'
		}

		app.getContext().then((context) => {
			url += '?login_hint=' + context.user.loginHint;

			const interaction_status = localStorage.getItem(`msal.${msal.browserStorage.clientId}.interaction.status`);
			if (interaction_status != null && interaction_status == '') {
				localStorage.setItem(`msal.${msal.browserStorage.clientId}.interaction.status`, '');
			}

			authentication.authenticate({
				url,
			}).then((response) => {
				console.log('success', response)
				localStorage.removeItem('dl-teams-auth-progress');
				this.props.onLogin(response);
			}).catch((error) => {
				console.log('error', JSON.stringify(error))
				if (msal.getAllAccounts().length > 0) {
					// Is logged in despite login failure
					let response = {
						account: msal.getAllAccounts()[0],
					};

					this.props.onLogin(response);
					return true;
				}

				this.setState({ error: JSON.stringify(error) });
				localStorage.removeItem('dl-teams-auth-progress');
			});
		});
	}

	render() {
		return (
			<div className="App">
				<div className="App-Container" style={{textAlign: 'center'}}>
					<header className="App-header">
						<img width="325" alt="Logotyp Internetstiftelsen" src="/iis_partnership_logo_grey.png"/>
					</header>

					<h2>Logga in med ditt konto</h2>

					<Button onClick={this.runLogin}>
						Logga in
					</Button>

					{this.state.error != null ?
						<div style={{ color: '#b2b2b2', marginTop: '1rem' }}>
							Något gick fel med inloggningen. Vänligen försök igen.<br />
							Felkod: {this.state.error}
						</div>
					: null}
				</div>
			</div>
		);
	}

}

export default AuthenticationPage;
