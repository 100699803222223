import React, { Component } from 'react';

import Chevron from './Chevron';

import './Collapsible.scss';

class Collapsible extends Component {

	constructor(props) {
		super(props);

		this.state = {
			open: false,
		}
	}

	toggle = () => {
		this.setState({ open: ! this.state.open });
	}

	render() {
		const { open } = this.state;

		return (
			<div className={open ? 'collapsible open' : 'collapsible'}>
				<div className="title" onClick={this.toggle}>
					{this.props.title}

					<Chevron />
				</div>

				<div className="content">
					{this.props.children}
				</div>
			</div>
		);
	}

}

export default Collapsible;