/* Import dependencies */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { app } from '@microsoft/teams-js';
import { appInsights } from 'libs/tracking';

import { getMe } from 'actions/User';
import { getLessons, searchLessons, resetSearchResults } from 'actions/Lessons';

/* Import components */
import LessonsList from 'UI/LessonsList';
import SearchForm from './SearchForm';

/* Import resources */
import './Style.scss';

class LessonsContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			lessons: [],
			lessonsLoaded: false,
			searchString: '',
			filters: [],
			filterResult: [],
		};

		this.timer = null;
	}
	
	onLogoutPress = (event) => {
		event.preventDefault();

		let url = window.location.href.split("?")[0] + "?";
		let queryParams = {};

		window.location.search.substr(1).split("&").forEach(function(item) {
			let s = item.split("="),
			k = s[0],
			v = s[1] && decodeURIComponent(s[1]);
			queryParams[k] = v;
		});

		delete queryParams["loginHint"];

		queryParams["userObjectId"] = "00000000-0000-0000-000000000000";
		queryParams['logged_out']   = 'user_action';

		url = window.location.origin + '/?';

		for ( var k in queryParams ) {
			url = url + k + "=" + encodeURIComponent(queryParams[k]) + "&";
		}

		localStorage.clear();
		localStorage.setItem('dl-app-logged-out', 'yes');

		window.location.href = url;
	}

	componentDidMount = () => {
		if (this.props.me == null) {
			this.props.getMe();
		}

		app.getContext().then((context) => {
			appInsights.trackPageView({
				name: 'Lessons',
				properties: {
					'tenant': context.user.tenant.id,
				},
			});
		});

		if (this.props.lessons.length == 0) {
			this.update();
		} else {
			this.setState({ lessonsLoaded: true });
		}
	}

	toggleFilter = (filter) => {
		let { filters } = this.state;
		const find = filters.findIndex(n => { return n === filter });

		if (find > -1) {
			filters.splice(find, 1);
		} else {
			filters.push(filter);
		}

		this.setState({ filters }, () => {
			this.applyFilters();
		});
	}

	isIframe() {
		try {
			return window.self !== window.top;
		} catch (e) {
			return true;
		}
	}

	applyFilters = () => {
		const { filters } = this.state;
		const { lessons } = this.props;
		let filteredLessons = lessons;

		filters.map(filter => {
			let meta = filter.split(':');
			meta = meta.filter(function(n) { return n !== '' || n });

			switch(meta[0]) {
				case 'subjectmatter':
					meta = parseInt( meta.pop(), 10 );
					filteredLessons = filteredLessons.filter(function(lesson) {
						const found = lesson.amnesomrade.find((subject) => {
							return subject === meta;
						});

						return found != null;
					});

					break;

				case 'schoolsubject':
					meta = parseInt( meta.pop(), 10 );
					filteredLessons = filteredLessons.filter(function(lesson) {
						const found = lesson.skolamne.find((subject) => {
							return subject === meta;
						});

						return found != null;
					});

				break;

				case 'grade':
					meta = parseInt(meta.pop(), 10);
					filteredLessons = filteredLessons.filter(function(lesson) {
						const found = lesson.arskurs.find((grade) => {
							return grade === meta;
						});

						return found != null;
					});

					break;

				default:
					return lessons;
			}

			return filter;
		});

		if (filteredLessons.length < 1) {
			filteredLessons = null;
		}

		this.setState({ filterResult: filteredLessons });
	}

	onSearchFormSubmission = (values) => {
		if (values.query == null || values.query == '') {
			this.props.resetSearchResults();
		} else {
			this.props.searchLessons(values.query);
		}
	}

	clearFilters = () => {
		this.setState({ filters: [], filterResult: [] });
	}

	onChangeSearch = (event) => {
		this.setState({ searchString: event.target.value }, () => {
			if (this.timer == null) {
				this.applyFilters();
			}

			if (this.timer != null) {
				clearTimeout(this.timer);

				this.timer = setTimeout(() => {
					this.applyFilters();
				}, 350);
			}
		});
	}

	update = async () => {
		await this.props.getLessons();
		this.setState({ lessonsLoaded: true });
	}

	renderSearchResults = () => {
		const { searchResults } = this.props;

		return <div>
			<div className="back-button">
				<Link to="#" style={{cursor: 'pointer'}} onClick={this.props.resetSearchResults}>
					&larr; Tillbaka till alla lektioner
				</Link>
			</div>

			<div className="list-items grid">
				{searchResults.map(lesson => {
					return <div className="lesson-list" key={'search-results-'+lesson.id}>
						<Link className="list-item-title" to={`/lesson/${lesson.id}`}>
							<h3 itemProp="name"
								dangerouslySetInnerHTML={{ __html: lesson.title }}
							/>
						</Link>
					</div>
				}) }
			</div>
		</div>
	}

	renderLessons = () => {
		const { filterResult, filters, lessonsLoaded } = this.state;
		const { lessons } = this.props;

		if (lessons.length === 0 && lessonsLoaded) {
			return <p className="App-intro">Inga lektioner hittades</p>
		}

		if (filters.length > 0) {
			return <div>
				{filters.length > 0 && filterResult === null ?
					<div className="search-error-result">
						<h3>Inga lektioner hittades med dina sökalternativ</h3>

						<Link to="#" style={{cursor: 'pointer'}} onClick={this.clearFilters}>
							Återställ filter
						</Link>
					</div>
				:
					filterResult && filterResult.length > 0 ?
						<LessonsList lessons={filterResult} />
				: null}
			</div>
		}

		return <LessonsList lessons={lessons} />
	}

	render() {
		const { lessonsLoaded, filters } = this.state;
		const { subjectMatters, schoolSubjects, grades } = this.props;

		return (
			<div className="Lessons">
				<div className="App-Container">
					<div className="back-button">
						<div className="logo">
							<a target="_blank" rel="noopener noreferrer" href="https://digitalalektioner.se/">
								<img width="125" alt="Logotyp Internetstiftelsen" src="iis_partnership_logo_grey.png"/>
							</a>&nbsp;

							<a target="_blank" rel="noopener noreferrer" href="https://haldor.se/digitalalektioner">
								<img width="100" alt="Logotype Haldor" src="haldor_logo_grey60.png"/>
							</a>
						</div>

						<div className="logout-container">
							{this.isIframe() ?
								<div style={{cursor: 'pointer'}} onClick={this.onLogoutPress}>
									Logga ut
								</div>
							:
								<div>
									Inloggad | <a href="https://digitalalektioner.se/">Gå till Digitala Lektioner</a>
								</div>
							}
						</div>

						<div style={{ clear: 'both' }} />
					</div>

					<div className="sort-container">
						<SearchForm onSubmit={this.onSearchFormSubmission} />

						{this.props.searchResults.length == 0 ?
							<div className="meta-select-container">
								<div className="column">
									<div className="name">
										Årskurs
									</div>

									{grades != null && grades.length > 0 ?
										grades.map(grade => {
											const find = filters.find(filter => {
												return filter === `grade:${grade.id}`;
											});

											const isActive = find != null;

											return <div
												key={`grade-${grade.id}`}
												onClick={() => this.toggleFilter(`grade:${grade.id}`)}
												className={isActive ? "item grade active" : "item grade"}
											>
												{grade.name}
											</div>
										})
									: null}
								</div>

								<div className="column">
									<div className="name">
										Ämnesområde
									</div>

									{subjectMatters != null && subjectMatters.length > 0 ?
										subjectMatters.map(matter => {
											const find = filters.find(filter => {
												return filter === `subjectmatter:${matter.id}`;
											});
											const isActive = find != null;

											return <div
												key={`submatter-${ matter.id }`}
												onClick={() => this.toggleFilter(`subjectmatter:${ matter.id }`)}
												className={isActive ? "item subject-matter active" : "item subject-matter"}
											>
												{matter.name}
											</div>;
										})
									: null}
								</div>

								<div className="column">
									<div className="name">
										Skolämne
									</div>

									{schoolSubjects != null && schoolSubjects.length > 0 ?
										schoolSubjects.map(subject => {
											const find = filters.find(filter => {
												return filter === `schoolsubject:${subject.id}`;
											});

											const isActive = find != null;

											return <div key={`schoolsub-${ subject.id }`}
												onClick={() => this.toggleFilter(`schoolsubject:${subject.id}`)}
												className={isActive ? "item school-subject active" : "item school-subject"}
											>
												{subject.name}
											</div>
										})
									: null}
								</div>

								<div style={{clear: 'both'}} />

								{filters.length > 0 ?
									<Link to="#" style={{marginTop: '1rem', display: 'block', cursor: 'pointer'}} onClick={this.clearFilters}>
										Återställ filter
									</Link>
								: null}
							</div>
						: null}
					</div>

					{! lessonsLoaded ?
						<p className="App-intro">Laddar lektioner...</p>
					: this.props.searchResults.length > 0 ?
						this.renderSearchResults()
					:
						this.renderLessons()}

					<div className="logo center">
						<a target="_blank" rel="noopener noreferrer" href="https://digitalalektioner.se/">
							<img width="185" alt="Logotyp Internetstiftelsen" src="iis_partnership_logo_grey.png"/>
						</a>&nbsp;

						<a target="_blank" rel="noopener noreferrer" href="https://haldor.se/digitalalektioner">
							<img width="155" alt="Logotyp Haldor" src="haldor_logo_grey60.png"/>
						</a>
					</div>

					<div style={{clear: 'both'}} />
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		me: state.User.me,
		schoolSubjects: state.Meta.schoolSubjects,
		subjectMatters: state.Meta.subjectMatters,
		grades: state.Meta.grades,
		lessons: state.Lessons.lessons,
		searchResults: state.Lessons.searchResults,
	};
};

export default connect(mapStateToProps, {
	getMe,
	getLessons,
	searchLessons,
	resetSearchResults,
})(LessonsContainer);
