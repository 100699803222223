import React, { Component } from 'react';
import { connect } from 'react-redux';

/* Import resources */
import './Style.scss';

class SubjectMatter extends Component {
	getSubjectMatter = ( id ) => {
		// Return the subject matter matching the id provided
		if ( ! id || id === null ) {
			return false;
		}

		const { subjectMatters } = this.props;
		if ( subjectMatters != null ) {
			const storedObject = subjectMatters.find( function( matt ) {
				return matt.id === id;
			} );

			if ( typeof( storedObject ) !== 'undefined' && storedObject !== null ) {
				return storedObject.name;
			}
		}

		return null;
	}


	render() {
		const { id } = this.props;

		if ( id ) {
			return(
				<div className="subject-matter">
					{ this.getSubjectMatter( id ) }
				</div>
			);
		} else {
			return <div />;
		}
	}
}

function mapStateToProps( state ) {
	return {
		subjectMatters: state.Meta.subjectMatters,
	};
};

export default connect( mapStateToProps )( SubjectMatter );
