import React, { Component } from 'react';

import Lesson from '../Lesson/';

/* Import resources */
import './Style.scss';

class LessonsList extends Component {
	render() {
		const { lessons } = this.props;
		
		if ( lessons.length < 1 ) {
			return null;
		}

		return(
			<div className="list-items grid">
				{ lessons.map( lesson => {
					return <Lesson key={ lesson.id } lesson={ lesson } />
				}) }
			</div>
		);
	}
}

export default LessonsList;
