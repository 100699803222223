import React, { Component } from 'react';
import Modal from 'react-modal';

class LoaderModal extends Component {
	render() {
		const { entire, loading, text } = this.props;

		let isopen = false;

		if ( entire || loading.length > 0 ) {
			isopen = true;
		}

		return(
			<Modal
				contentLabel=""
				isOpen={ isopen }
				style={{
					overlay: {
						cursor: 'wait',
						zIndex: 9999990,
						backgroundColor: 'rgba(0,0,0,0.45)',
					},
					content: {
						cursor: 'wait',
						zIndex: 9999991,
						top: '50%',
						left: '50%',
						right: 'auto',
						bottom: 'auto',
						marginRight: '-50%',
						width: '85%',
						transform: 'translate(-50%, -50%)',
						padding: '0',
						fontFamily: 'helvetica',
					}
				}}>

				<div style={{ marginTop:'200px', marginBottom:'200px', textAlign: 'center', width:'100%'}}>
					{ loading.length > 0 ?
						<div>
							{ loading.map( lpart => {
								return <span key={ `save-indicator-${ lpart.lesson_part }` }>
									Sparar lektionsdel { lpart.lesson_part }: { lpart.header }...<br />

									{ text != null ?
										<span style={{ display: 'block', marginTop: '1em' }}>{ text }</span>
									: null }
								</span>;
							}) }
						</div>
					: null }

					{ entire ?
						<div>
							<span>Sparar uppgift till Klassanteckningsboken...</span><br />

							{ text != null ?
								<span style={{ display: 'block', marginTop: '1em' }}>{ text }</span>
							: null }
						</div>
					: null }
				</div>
			</Modal>
		);
	}
}

export default LoaderModal;
