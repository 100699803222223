
export const modal = {
	overlay: {
		zIndex: 9999990,
		backgroundColor: 'rgba(0,0,0,0.45)',
	},
	content: {
		zIndex: 9999991,
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		width: '55%',
		transform: 'translate(-50%, -50%)',
		padding: '0',
		fontFamily: 'helvetica',
	}
};
