import React, { Component } from 'react';
import HTML from 'UI/HTML/';

class LessonContent extends Component {

	parseLinksInContent = (html) => {
		var content = document.createElement('div');
		content.innerHTML = html;

		var links = content.getElementsByTagName('a');

		for (var c = 0; c < links.length; c++) {
			links[c].target = '_blank';
		}

		return content.innerHTML;
	}

	render() {
		const { lesson } = this.props;

		return (
			<div>
				{lesson.lesson_parts.map(lesson_part => {
					return <div key={`lesson_part-${lesson_part.lesson_part}`}>
						<div className="lesson-part-title">
							<h2 id={`part-${lesson_part.lesson_part}`}>
								{lesson_part.lesson_part}.&nbsp;

								<HTML>
									{this.parseLinksInContent(lesson_part.header)}
								</HTML>
							</h2>
						</div>

						<div className="instructions">
							<h3>Instruktioner</h3>

							<HTML>
								{this.parseLinksInContent(lesson_part.summary)}
							</HTML>
						</div>

						<HTML>
							{this.parseLinksInContent(lesson_part.article_src)}
						</HTML>
					</div>;
				})}
			</div>
		);
	}
}

export default LessonContent;