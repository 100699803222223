import React, { Component } from 'react';
import { connect } from 'react-redux';

/* Import resources */
import './Style.scss';

class SchoolSubject extends Component {
	getSchoolSubject = ( id ) => {
		// Return the school subject object matching the id provided
		if ( ! id || id === null ) {
			return false;
		}

		const { schoolSubjects } = this.props;
		if ( schoolSubjects != null ) {
			const storedObject = schoolSubjects.find( function( subj ) {
				return subj.id === id;
			} );

			if ( typeof( storedObject ) !== 'undefined' && storedObject !== null ) {
				return storedObject.name;
			}
		}

		return null;
	}

	render() {
		const { id } = this.props;

		if ( id ) {
			return(
				<div key={ `school-subject-tag-${ id }` } className="school-subject">
					{ this.getSchoolSubject( id ) }
				</div>
			);
		} else {
			return <div />;
		}
	}
}

function mapStateToProps( state ) {
	return {
		schoolSubjects: state.Meta.schoolSubjects,
	};
};

export default connect( mapStateToProps )( SchoolSubject );
