let config = {
	cacheLocation: 'localStorage',
	tenant: 'common',
	clientId: '',
	instance: 'https://login.microsoftonline.com/',
	redirectUri: window.location.origin + '/',
	version: 'beta/',
	graphUri: 'https://graph.microsoft.com/',
	authority: "https://login.microsoftonline.com/common",
	navigateToLoginRequestUrl: false,
	endpoints: {
		graph: 'https://graph.microsoft.com',
		'https://www.onenote.com/api/': 'https://www.onenote.com/api/',
	},
};

export const GET_CONFIG = () => {
	if ( window.location.host.indexOf( 'localhost' ) > -1 ||
		 window.location.host.indexOf( 'ngrok.io' ) > -1 ||
		 window.location.host.indexOf( 'diglekdev.azurewebsites.net' ) > -1 ) {
		// Dev environment
		// localhost or ngrok domain
		config.clientId = 'dab58246-8943-40c3-a78b-d30dac2a669f';
	} else {
		// Production environment
		// (all other domains)
		config.clientId = '4a0e2748-4ce6-4d89-8d9c-8b58f8e7e768';
	}

	return config;
}
