/* Import dependencies */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import SubjectMatter from 'UI/Meta/SubjectMatter';
import SchoolSubject from 'UI/Meta/SchoolSubject';
import Grade from 'UI/Meta/Grade';

/* Import resources */
import './Style.scss';

class Lesson extends Component {
	render() {
		const { lesson } = this.props;

		if (typeof (lesson) === 'undefined') {
			return null;
		}

		return (
			<div key={lesson.id} className="lesson-list">
				<Link className="list-item-title" to={`/lesson/${lesson.id}`}>
					<h3 itemProp="name"
						dangerouslySetInnerHTML={{ __html: lesson.title.rendered }}
					/>
				</Link>

				<footer>
					{lesson.amnesomrade.map(subject => {
						return <SubjectMatter
							key={`lessson-subjectmatter-${subject}`}
							id={subject}
						/>
					})}

					{lesson.skolamne.map(subject => {
						return <SchoolSubject
							key={`lessson-schoolsubject-${subject}`}
							id={subject}
						/>
					})}

					{lesson.arskurs.map(grade => {
						return <Grade
							key={`grade-${grade}`}
							id={grade}
						/>
					})}
				</footer>
			</div>
		);
	}
}

export default Lesson;