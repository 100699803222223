/*******************************************************************************************************
 *
 * Import polyfills for browser compatibility
 *
 * This file should be included in the root file of the app.
 *
 * Resources:
 * 1. https://stackoverflow.com/questions/43756211/best-way-to-polyfill-es6-features-in-react-app-that-uses-create-react-app
 * 2. https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/find#Polyfill
 * 3. https://stackoverflow.com/questions/37788536/object-doesnt-support-property-or-method-find-in-ie
 * 4. https://github.com/zloirock/core-js
 *
 * This could also be done directly in the webpack config but this file is a work around
 * for create-react-app
 *
 *******************************************************************************************************/

// Array functions
import 'core-js/fn/array/find';
import 'core-js/fn/array/find-index';
import 'core-js/fn/array/includes';
import 'core-js/fn/array/map';
import 'core-js/fn/array/filter';
import 'core-js/fn/array/sort';

// Number functions
import 'core-js/fn/number/is-nan';


// Window.location.origin fix
/*******************************************************************************************************
 * Some versions of IE does not include this variable that is heavily used in this app
 * So this snippet adds the window variable if its missing
 *******************************************************************************************************/
if ( ! window.location.origin ) {
	window.location.origin = window.location.protocol +
		"//" +
		window.location.hostname +
		( window.location.port ? ':' + window.location.port: '' );
}
