/* Import dependencies */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { GET_CONFIG } from 'libs/config';

import {
	getSubjectMatters,
	getSchoolSubjects,
	getGrades,
} from 'actions/Meta';

/* Import containers */
import LessonsContainer from '../LessonsContainer';
import LessonContainer from '../LessonContainer';

import Logout from 'authentication/Logout';

import ConfigurationPage from 'Teams/ConfigurationPage';
import RemovalPage from 'Teams/RemovalPage';
import AuthenticationPopup from 'authentication/AuthenticationPopup';
import GenerateToken from 'Teams/GenerateToken';

import ScrollToTop from 'UI/ScrollToTop';

/* Import resources */
import './Style.scss';

/* Define constants */
const config = GET_CONFIG();
export const API_URL = config.graphUri + config.version;

class AppContainer extends Component {

	constructor(props) {
		super(props);

		this.authenticationNeededEndpoints = [
			'lessons', 'lesson'
		];
	}

	isMobile() {
		try {
			return window.navigator.userAgent.indexOf('TeamsMobile') > -1;
		} catch (e) {
			return true;
		}
	}

	detectIE = () => {
		// https://codepen.io/gapcode/pen/vEJNZN
		var ua = window.navigator.userAgent;

		var msie = ua.indexOf('MSIE ');
		if (msie > 0) {
			// IE10
			return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
		}

		var trident = ua.indexOf('Trident/');
		if (trident > 0) {
			// IE11
			var rv = ua.indexOf('rv:');
			return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
		}

		return false;
	}

	componentDidMount = () => {
		if (this.props.subjectMatters == null) {
			this.props.getSubjectMatters();
		}

		if (this.props.schoolSubjects == null) {
			this.props.getSchoolSubjects();
		}

		if (this.props.grades == null) {
			this.props.getGrades();
		}
	}

	shouldShowHeader = () => {
		if (window.location.pathname == '/') {
			return true;
		}

		let currentEndpoints = window.location.pathname.split('/');
		currentEndpoints = currentEndpoints.filter(function (n) { return n !== '' });

		let isAllowed = this.authenticationNeededEndpoints.findIndex(function (end) {
			return end === currentEndpoints[0];
		});

		if (isAllowed !== -1) {
			return true;
		}

		return false
	}

	render() {
		let background = '#f3f2f1';

		if (this.shouldShowHeader()) {
			background = 'white';
		}

		if (window.location.pathname.indexOf('configure') > -1) {
			background = 'white';
		}

		if (this.detectIE() != false) {
			return (
				<div className="App" style={{ backgroundColor: background, textAlign: 'center' }}>
					<p>
						This browser is not support at this moment, please upgrade to a newer browser.<br /><br />
						Appen stödjer inte din webbläsare för tillfället, vänligen uppdatera till en modernare
						webbläsare och försök igen.
					</p>
				</div>
			);
		}

		return (
			<div className="App" style={{ backgroundColor: background }}>
				<ScrollToTop>
					<Switch>
						<Route
							exact path='/'
							component={LessonsContainer}
						/>

						<Route
							path="/login"
							component={AuthenticationPopup}
						/>

						<Route
							path="/redirect"
							component={GenerateToken}
						/>

						<Route
							path="/logout"
							component={Logout}
						/>

						<Route
							path="/teams/remove"
							component={RemovalPage}
						/>

						<Route
							path="/teams/configure"
							component={(props) => {
								return <ConfigurationPage
									{...props} />
							}}
						/>

						<Route
							path="/configure"
							component={ConfigurationPage}
						/>

						<Route
							path="/lesson/:id"
							component={LessonContainer}
						/>
					</Switch>
				</ScrollToTop>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		me: state.User.me,
		subjectMatters: state.Meta.subjectMatters,
		schoolSubjects: state.Meta.schoolSubjects,
		grades: state.Meta.grades,
	};
};

export default withRouter(
	connect(mapStateToProps, {
		getSubjectMatters,
		getSchoolSubjects,
		getGrades,
	}, null, {
		pure: false,
	})(AppContainer));