import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const appInsights = new ApplicationInsights({
	config: {
		instrumentationKey: 'e5b2453b-ee77-4091-ac33-35b7e920f529',
		disableExceptionTracking: false,
		enableAutoRouteTracking: true,
	}
});

appInsights.loadAppInsights();
appInsights.trackPageView();

export {
	appInsights,
};