import React, { Component } from 'react';
import { app, pages } from '@microsoft/teams-js';
import { appInsights } from 'libs/tracking';

class ConfigurationPage extends Component {

	componentDidMount = async () => {
		await app.initialize();

		app.getContext().then((context) => {
			appInsights.trackEvent({
				name: 'Begin configuration',
				properties: {
					'tenant': context.user.tenant.id,
				},
			});
		});

		pages.config.registerOnSaveHandler(function (saveEvent) {
			pages.config.setConfig({
				websiteUrl: 'https://digitalalektioner.se',
				entityId: 'diglek-content',
				suggestedDisplayName: 'Digitala Lektioner',
				contentUrl: `${window.location.origin}/`,
				removeUrl: `${window.location.origin}/teams/remove`,
			}).then(() => {
				saveEvent.notifySuccess();
			});
		});

		pages.config.setValidityState(true);
	}

	render() {
		return (
			<div className="app-container">
				<p>
					Denna app behöver inte konfigureras, för att lägga till appen i det valda teamet tryck på "Spara"
				</p>
			</div>
		);
	}

}

export default ConfigurationPage;
