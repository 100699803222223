
export const teacher = [
	'94763226-9b3c-4e75-a931-5c89701abe66', // O365 Education, Staff
	'78e66a63-337a-4a9a-8959-41c6654dfb56', // O365 Education Plus, Staff
	'e578b273-6db4-4691-bba0-8d691f4da603', // A3 Staff (ENTERPRISEPACKPLUS_FACULTY)
	'4b590615-0888-425a-a965-b3bf7789848d', // A3 Staff (M365EDU_A3_FACULTY)
	'a4585165-0533-458a-97e3-c400570268c4', // A5 Staff (ENTERPRISEPREMIUM_FACULTY)
	'e97c048c-37a4-45fb-ab50-922fbf07a370', // A5 Staff (M365EDU_A5_FACULTY)
];

export const student = [
	'314c4481-f395-4525-be8b-2ec4bb1e9d91', // O365 Education, Student
	'e82ae690-a2d5-4d76-8d30-7c6e01e6022e', // O365 Education Plus, Student
	'98b6e773-24d4-4c0d-a968-6e787a1f82',   // A3 Student (ENTERPRISEPACKPLUS_STUDENT)
	'18250162-5d87-4436-a834-d795c15c80f3', // A3 Student (M365EDU_A3_STUUSEBNFT)
	'46c119d4-0379-4a9d-85e4-97c66d3f909e', // A5 Student (M365EDU_A5_STUDENT)
];