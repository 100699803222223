/* Import polyfills */
import 'libs/polyfills.js';

/* Import dependencies */
import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import { BrowserRouter } from 'react-router-dom';
import { app } from "@microsoft/teams-js";

// Init tracking libs
import 'libs/tracking';

/* Import containers */
import AuthenticationProvider from 'authentication/AuthenticationProvider';
import AppContainer from 'containers/AppContainer';

/* Import redux dependencies */
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reducers from 'reducers';
import promise from 'redux-promise';

/* Constants */
const supportsHistory = 'pushState' in window.history;
const store = createStore(reducers, applyMiddleware(
	promise,
));

/* Init MS Teams SDK */
app.initialize();

ReactDOM.render((
	<Provider store={store}>
		<BrowserRouter forceRefresh={!supportsHistory}>
			<AuthenticationProvider>
				<AppContainer />
			</AuthenticationProvider>
		</BrowserRouter>
	</Provider>
), document.getElementById('root'));

registerServiceWorker();