import React from 'react';

function createMarkup( markup ) {
	return {__html: markup};
}

export default function HTML( props ) {
	return(
		<span dangerouslySetInnerHTML={ createMarkup( props.children ) } />
	);
}
