// Import action constants
import {
	GET_SUBJECT_MATTERS,
	GET_SCHOOL_SUBJECTS,
	GET_GRADES,
} from 'actions/Meta';

// Define the initial state
const INITIAL_STATE = {
	subjectMatters: null,
	schoolSubjects: null,
	grades: null,
};

const MetaReducer = (state = INITIAL_STATE, action) => {
	const { type, payload } = action;

	switch ( type ) {
		case GET_SUBJECT_MATTERS:
			return { ...state, subjectMatters: payload };

		case GET_SCHOOL_SUBJECTS:
			return { ...state, schoolSubjects: payload };

		case GET_GRADES:
			return { ...state, grades: payload };

		default:
			return state;
	}
}

export default MetaReducer;
