/* Import dependencies */
import React, { Component } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { app } from "@microsoft/teams-js";
import { appInsights } from 'libs/tracking';
import { http } from 'libs/msal';

/* Import UI components */
import HTML from 'UI/HTML/';
import SubjectMatter from 'UI/Meta/SubjectMatter/';
import SchoolSubject from 'UI/Meta/SchoolSubject/';
import Collapsible from 'UI/Collapsible';

/* Import partials */
import SaveButtons from './Partials/saveButtons';
import LessonContent from './Partials/lessonContent';
import SavedPartModal from './Partials/savedPartModal';
import LoaderModal from './Partials/loaderModal';
import { Helper } from './Helper.js';

/* Import resources */
import './Style.scss';

const q = require('q');

class LessonContainer extends Component {
	constructor(props) {
		super(props);

		this.state = {
			hasLoaded: false,
			modalIsOpen: false,
			loading: [],
			lessonsDetails: [],
			activeLesson: null,
			schoolSubjects: [],
			subjectMatters: [],
			savedNotebook: null,
			noteBookErrors: [],
			activePart: null,
			entire: false,
			entireModal: false,
			classNotebook: null,
			saveText: '',
			alert: null,
		}
	}

	componentWillUnmount = () => {
		localStorage.setItem('lessonState', JSON.stringify(this.state.lessonsDetails));
	}

	componentDidMount = () => {
		const { match } = this.props;
		const rehydrate = JSON.parse(localStorage.getItem('lessonState'));

		if (rehydrate !== null) {
			this.setState({ lessonsDetails: rehydrate }, () => {
				this.getLessonContents(match.params.id);
			});
		} else {
			this.getLessonContents(match.params.id);
		}

		this.update();
	}

	getLessonContents = (lessonId) => {
		let { lessonsDetails } = this.state;
		this.setState({ hasLoaded: false });

		// Look for a object with the same id in the saved store
		let savedObject = lessonsDetails.find(function (less) {
			return less.id === parseInt(lessonId, 10);
		});

		if (typeof (savedObject) === 'undefined') {
			// We need to fetch the data about this object
			Axios.get(`https://digitalalektioner.se/wp-json/wp/v2/lessons/${lessonId}`, {
				withCredentials: true,
			}).then((response) => {
				lessonsDetails.push(response.data);

				this.setState({ activeLesson: response.data, lessonsDetails, hasLoaded: true }, () => {
					app.getContext().then((context) => {
						appInsights.trackPageView({
							name: 'Visit lesson - ' + this.state.activeLesson.title.rendered,
							properties: {
								'tenant': context.user.tenant.id,
							}
						});
					});

					this.fetchMediaIfNeeded();
				});
			});
		} else {
			this.setState({ activeLesson: savedObject, hasLoaded: true });
		}
	}

	fetchMediaIfNeeded = () => {
		let { activeLesson, lessonsDetails } = this.state;

		Helper.fetchMediaIfNeeded(activeLesson).then((lesson) => {
			let index = lessonsDetails.findIndex(function (less) {
				return less.id === lesson.id;
			});

			lessonsDetails.splice(index, 1);
			lessonsDetails.push(lesson);

			this.setState({ activeLesson: lesson, lessonsDetails });
		});
	}

	fetchSubjectMatters = () => {
		Axios.get('https://digitalalektioner.se/wp-json/wp/v2/amnesomrade/', {
			withCredentials: true,
		}).then((response) => {
			let { data } = response;
			this.setState({ subjectMatters: data });
		});
	}

	fetchSchoolSubjects = () => {
		Axios.get('https://digitalalektioner.se/wp-json/wp/v2/skolamne/', {
			withCredentials: true,
		}).then((response) => {
			let { data } = response;
			this.setState({ schoolSubjects: data });
		});
	}

	fetchOrCreateNotebook = () => {
		const deferred = q.defer();

		let group_id = null;

		app.getContext().then((context) => {
			group_id = context.team.groupId;

			Helper.fetchOrCreateNotebook(group_id).then(notebook => {
				deferred.resolve(notebook);
			}).catch(() => {
				deferred.reject(0);
			});
		});

		return deferred.promise;
	}

	saveLessonPart = (part) => {
		const { loading, activeLesson } = this.state;

		let loader = loading;
		loader.push(part);

		appInsights.trackEvent({ name: 'SaveLessonPart - ' + activeLesson.title.rendered });

		this.setState({ loading: loader, activePart: part, saveText: 'Hämtar anteckningsbok...', alert: null });
		this.fetchOrCreateNotebook().then(notebook => {
			// Use the sections url provided by the Notebook object to get all the sections in the NoteBook
			this.setState({ saveText: 'Hämtar eller skapar sektion...' });

			Helper.fetchOrCreateSection(notebook, activeLesson.title.rendered).then((section) => {
				// Build page body
				let content = Helper.handleIframeInContent(part.article_src);

				this.setState({ saveText: 'Förbereder innehåll...' });
				var page_body = `
					<!doctype html>
					<html>
						<head>
							<title>${part.lesson_part}: ${part.header}</title>
						</head>

						<body>
							<h2>Instruktioner</h2>
							<p>${part.summary}</p>
							<br />
							<p>${content}</p>
						</body>
					</html>
				`;

				this.setState({ saveText: 'Kontrollerar klassanteckningsbok...' });
				this.fetchOrCreateClassNotebook(section).then((classNotebook) => {
					// After the Notebook + Section are succesfully created
					// We proceed creating a "page" of the selected lesson part

					this.setState({ saveText: 'Sparar sida...' });
					http({
						url: section.pagesUrl,
						method: 'post',
						data: page_body,
						headers: {
							'Content-Type': 'text/html',
							'Accept': 'application/json',
						},
					}).then((response) => {
						const sectionIds = {
							"sectionIds": [
								section.id,
							],
						};

						http({
							url: `${classNotebook.self}/copySectionsToContentLibrary`,
							method: 'post',
							data: JSON.stringify(sectionIds),
							headers: {
								'Content-Type': 'application/json',
								'Accept': 'application/json',
								'CustomUserAgent': 'Teams/1.0',
							},
						}).then((sectionResponse) => {
							if (sectionResponse.status === 201) {
								let thisLoader = loader.findIndex(function (lpart) {
									return lpart.lesson_part === part.lesson_part;
								});

								loader.splice(thisLoader, 1);

								this.setState({
									loading: loader,
									savedNotebook: response.data,
									modalIsOpen: true,
									saveText: 'Klart!',
									alert: 'Lektion sparad!',
								});

								document.scrollingElement.scrollTop = 0;
							}

							return sectionResponse;
						});
					}).catch(() => {
						console.error('Could not create page, check network tab for debuging');

						this.setState({ entire: false, loading: [], alert: 'Något gick fel! Kunde inte skapa sida!' });
						document.scrollingElement.scrollTop = 0;
					});
				}).catch(() => {
					// Could not get class notebook for team
					this.setState({ entire: false, loading: [], alert: 'Kunde inte spara innehållet. Har du ställt in en klassanteckningsbok för detta team?' });
					document.scrollingElement.scrollTop = 0;
				});
			});
		}).catch(() => {
			this.lessonPartError('E04 - Det verkar som att detta team saknar klassanteckningsbok. Klassanteckningsboken måste finnas innan du kan kopiera lektionsmaterial.');
		});
	}

	lessonPartError = (error) => {
		if (error.length < 4) {
			error = error + ' - Något gick fel! Försök igen';
		}

		this.setState({
			loading: [],
			modalIsOpen: false,
			saveText: 'Något gick fel!',
			alert: error,
		});

		document.scrollingElement.scrollTop = 0;

		app.getContext().then((context) => {
			appInsights.trackTrace({
				message: 'Save lesson part error',
				properties: {
					'error': error,
					'tenant': context.user.tenant.id,
				},
			});
		});

		window.scrollTo(0, 0);
	}

	saveEntireLesson = () => {
		const { activeLesson } = this.state;

		appInsights.trackEvent({ name: 'SaveEntireLesson - ' + activeLesson.title.rendered });

		this.setState({ entire: true, saveText: 'Förbereder innehåll...', alert: null });

		let lesson_parts = '';
		activeLesson.lesson_parts.map(part => {
			let summary = Helper.handleIframeInContent(part.summary);
			let article = Helper.handleIframeInContent(part.article_src);

			lesson_parts += '<h1>' + part.header + '</h1><br />';
			lesson_parts += '<h3>Instruktioner</h3><br />';
			lesson_parts += summary;
			lesson_parts += '<br />';
			lesson_parts += article;
			lesson_parts += '<br />';

			return part;
		});

		this.fetchOrCreateNotebook().then((notebook) => {
			// Use the sections url provided by the Notebook object to get all the sections in the NoteBook
			this.setState({ saveText: 'Hämtar eller skapar sektion...' });
			Helper.fetchOrCreateSection(notebook, activeLesson.title.rendered).then((section) => {
				// Build page body
				var page_body = `
						<!doctype html>
						<html>
							<head>
								<title>${activeLesson.title.rendered}</title>
							</head>

							<body>
								${lesson_parts}
							</body>
						</html>
					`;

				this.setState({ saveText: 'Kontrollerar klassanteckningsbok...' });
				this.fetchOrCreateClassNotebook(section).then((classNotebook) => {
					if (classNotebook == null || classNotebook.length == 0) {
						this.setState({ entire: false, loading: [], alert: 'Kunde inte spara innehållet. Har du ställt in en klassanteckningsbok för detta team?' });
						document.scrollingElement.scrollTop = 0;
						return false;
					}

					this.setState({ saveText: 'Sparar sida...' });

					http({
						url: section.pagesUrl,
						method: 'post',
						data: page_body,
						headers: {
							'Content-Type': 'text/html',
							'Accept': 'application/json',
						},
					}).then((createdPage) => {
						const sectionIds = {
							"sectionIds": [
								section.id,
							],
						};

						http({
							url: `${classNotebook.self}/copySectionsToContentLibrary`,
							method: 'post',
							data: JSON.stringify(sectionIds),
							headers: {
								'Content-Type': 'application/json',
								'Accept': 'application/json',
								'CustomUserAgent': 'Teams/1.0',
							},
						}).then((sectionResponse) => {
							if (sectionResponse.status === 201) {
								this.setState({
									entire: false,
									savedNotebook: createdPage.data,
									entireModal: true,
									saveText: 'Klar!',
									alert: 'Lektion sparad!',
								});
							}
						});
					}).catch(() => {
						// Could not get class notebook for team
						this.setState({ entire: false, loading: [], alert: 'Kunde inte spara innehållet. Har du ställt in en klassanteckningsbok för detta team?' });
						document.scrollingElement.scrollTop = 0;
					});
				}).catch(() => {
					// Could not get class notebook for team
					this.setState({ entire: false, loading: [], alert: 'Kunde inte spara innehållet. Har du ställt in en klassanteckningsbok för detta team?' });
					document.scrollingElement.scrollTop = 0;
				});
			});
		});
	}

	fetchOrCreateClassNotebook = (section) => {
		const deferred = q.defer();

		app.getContext().then((context) => {
			const headers = {
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'CustomUserAgent': 'Teams/1.0',
			};

			const api = 'https://www.onenote.com/api/v1.0/';
			http({
				url: api + `myOrganization/siteCollections/FromUrl(url='${context.sharePointSite.teamSiteUrl}')`,
				method: 'get',
				headers: headers,
			}).then(siteInformationResponse => {
				const site = siteInformationResponse.data;

				http({
					url: api + `myOrganization/siteCollections/${site.siteCollectionId}/sites/${site.siteId}/notes/classNotebooks`,
					method: 'get',
					headers: headers,
				}).then((notebooksResponse) => {
					const { data } = notebooksResponse;

					if (data.value.length > 0) {
						this.setState({ classNotebook: data.value[0] });
						deferred.resolve(data.value[0]);
					} else {
						deferred.reject(0);
					}
				}).catch((error) => {
					deferred.reject(0);
				});
			});
		});

		return deferred.promise;
	}

	update = () => {
		this.fetchSubjectMatters();
		this.fetchSchoolSubjects();
	}

	modalToggle = () => {
		this.setState({ modalIsOpen: !this.state.modalIsOpen });
	}

	entireModalToggle = () => {
		this.setState({ entireModal: !this.state.entireModal });
	}

	render() {
		const {
			activeLesson,
			hasLoaded,
			modalIsOpen,
			savedNotebook,
			entire,
			loading,
			classNotebook,
		} = this.state;

		if (!hasLoaded) {
			return <div className="single-lesson">
				<div className="App-Container">Laddar</div>
			</div>;
		}

		if (activeLesson == null) {
			return null;
		}

		return (
			<div className="single-lesson">
				{this.state.alert != null ?
					<div className="alert-container-offset" />
					: null}

				{this.state.alert != null ?
					<div className="alert-container">
						<div className="alert">
							{this.state.alert}
						</div>
					</div>
					: null}

				<SavedPartModal
					modalToggle={this.modalToggle}
					modalIsOpen={modalIsOpen}
					savedNotebook={savedNotebook}
					classNotebook={classNotebook}
				/>

				<LoaderModal
					entire={entire}
					loading={loading}
					text={this.state.saveText}
				/>

				<div className="App-Container">
					<div className="back-button">
						<Link to="/">
							&larr; Gå tillbaka
						</Link>
					</div>

					<div className="lesson-title">
						<h1 dangerouslySetInnerHTML={{ __html: activeLesson.title.rendered }} />
					</div>
				</div>

				<div className="lesson-content">
					<div className="App-Container">
						<div className="text">
							<HTML>
								{Helper.parseLinksInContent(activeLesson.content.rendered)}
							</HTML>
						</div>

						{typeof (activeLesson.image_link) !== 'undefined' && activeLesson.image_link ?
							<div className="image">
								<img alt={activeLesson.image_title} src={activeLesson.image_link} />
							</div>
							: null}

						<div className="clearfix" />
					</div>

					<div className="App-Container">
						<div className="left-section">
							<Collapsible title="Se lärarinstruktioner">
								<HTML>
									{Helper.parseLinksInContent(activeLesson.teacher_instructions)}
								</HTML>
							</Collapsible>

							<div style={{ marginTop: '1rem' }} />

							<Collapsible title="Se läroplanskoppling">
								<HTML>
									{Helper.parseLinksInContent(activeLesson.curriculum)}
								</HTML>
							</Collapsible>

							<div className="lesson-parts-wrapper">
								<LessonContent lesson={activeLesson} />
							</div>
						</div>

						<div className="right-section">
							<h4 className="sidebar-headline">
								Etiketter
							</h4>

							{activeLesson.amnesomrade.map(subject => {
								return <SubjectMatter
									key={`lessson-subjectmatter-${subject}`}
									id={subject}
								/>
							})}

							{activeLesson.skolamne.map(subject => {
								return <SchoolSubject
									key={`lessson-schoolsubject-${subject}`}
									id={subject}
								/>
							})}

							<SaveButtons
								lesson={activeLesson}
								onEntire={this.saveEntireLesson}
								onPart={this.saveLessonPart}
								loading={this.state.loading}
								entire={entire}
							/>
						</div>

						<div className="clearfix" />
					</div>
				</div>
			</div>
		);
	}
}

export default LessonContainer;