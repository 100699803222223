import * as msal from '@azure/msal-browser';
import { GET_CONFIG } from './config';
import axios from 'axios';
import { authentication } from '@microsoft/teams-js';

/**
 * @typedef {Object} MSALConfig MSAL Config model
 */
const msalConfig = {
	auth: {
		clientId: GET_CONFIG().clientId,
		authority: 'https://login.microsoftonline.com/common/',
		postLogoutRedirectUri: window.location.origin + '/',
		redirectUri: window.location.origin + '/',
		navigateToLoginRequestUrl: false,
	},
	cache: {
		cacheLocation: "localStorage",
		storeAuthStateInCookie: false,
	},
	system: {
		loggerOptions: {
		  loggerCallback: (level, message, containsPii) => {
			if (containsPii) {
				return;
			}

			switch (level) {
			  case msal.LogLevel.Error:
				console.error(message);
				return;
			  case msal.LogLevel.Info:
				console.info(message);
				return;
			  case msal.LogLevel.Verbose:
				console.debug(message);
				return;
			  case msal.LogLevel.Warning:
				console.warn(message);
				return;
			  default:
				console.log(message);
				return;
			}
		  },
		  piiLoggingEnabled: false,
		},
		windowHashTimeout: 60000,
		iframeHashTimeout: 6000,
		loadFrameTimeout: 0,
	},
};

/**
 * @type {Array} loginScopes
 */
const loginScopes = [
	"openid",
	"profile",
	"offline_access",
];

/**
 * @type {Array} graphScopes
 */
const graphScopes = [
	"User.Read",
	"Notes.Create",
	"Notes.ReadWrite",
	"Notes.ReadWrite.All",
];

/**
 * @type {Array} onenoteScopes
 */
const onenoteScopes = [
	"https://onenote.com/Notes.Create",
	"https://onenote.com/Notes.ReadWrite.CreatedByApp",
];

/**
 * @type {RedirectRequest} loginRequest
 */
const loginRequest = {
	scopes: [
		...loginScopes,
		...graphScopes,
		...onenoteScopes,
	],
};

/**
 * @type {RedirectRequest} interactiveRequest
 */
const interactiveRequest = {
	scopes: [
		...loginScopes,
		...graphScopes,
		...onenoteScopes,
	],
	prompt: "select_account",
};

const EVENT_MSAL_TOKEN_ERROR = "MSAL_EVENTS_TOKEN_ERROR";
const EVENT_MSAL_LOGIN_REQUIRED = "MSAL_EVENTS_LOGIN_REQUIRED";

const instance = new msal.PublicClientApplication(msalConfig);

const getToken = (request) => new Promise(async (resolve, reject) => {
	if (instance.getAllAccounts().length == 0) {
		reject('User not logged in');
		return true;
	}

	request.account = getUser();

	const token = await new Promise(async (resolveToken, rejectToken) => {
		instance.acquireTokenSilent(request).then((token) => {
			resolveToken(token);
		}).catch(async (error) => {
				if (
					error.errorMessage.indexOf('AADSTS50173') > -1 ||
					error.errorMessage.indexOf('AADSTS230017') > -1 ||
					error.errorMessage.indexOf('AADSTS50133') > -1 ||
					error.errorMessage.indexOf('AADSTS50058') > -1 ||
					error.errorMessage.indexOf('AADSTS700081') > -1 ||
					error.errorMessage.indexOf('AADSTS65001') > -1 ||
					error.errorMessage.indexOf('No tokens were found for the given scopes') > -1
				) {
					const errorEvent = new CustomEvent(EVENT_MSAL_LOGIN_REQUIRED, {
						detail: error,
					});

					window.dispatchEvent(errorEvent)

					rejectToken(error);
					return false;
				}

				if (localStorage.getItem('dl-teams-auth-progress') == null) {
					localStorage.setItem('dl-teams-auth-request', JSON.stringify(request));
					localStorage.setItem('dl-teams-auth-progress', 'token');

					authentication.authenticate({
						url: '/GenerateToken',
					}).then((response) => {
						localStorage.removeItem('dl-teams-auth-progress');
						localStorage.removeItem('haldor-teams-auth-request');
						resolveToken(response)
					}).catch((error) => {
						localStorage.removeItem('dl-teams-auth-progress');
						localStorage.removeItem('haldor-teams-auth-request');
						rejectToken(error);
					});
				}
			});
	}).catch((error) => {
		reject(error);
	})

	resolve(token);
});

const onMsalTokenError = (callback) => {
	window.addEventListener(EVENT_MSAL_TOKEN_ERROR, (event) => {
		callback(event.detail);
	});
}

const onMsalLoginRequired = (callback) => {
	window.addEventListener(EVENT_MSAL_LOGIN_REQUIRED, (event) => {
		callback(event.detail);
	});
}

const getUser = () => {
	if (instance.getAllAccounts().length > 0) {
		const username = localStorage.getItem('session.username');

		if (username != null) {
			return instance.getAllAccounts().find(account =>
				account.username == username
			);
		} else {
			return instance.getAllAccounts()[0];
		}
	}

	return null;
}

const setUser = (username) => {
	localStorage.setItem('session.username', username);
}

const getUserId = () => {
	const user = getUser();

	if (user != null) {
		if (user.homeAccountId.indexOf('.') > -1) {
			return user.homeAccountId.split('.')[0];
		}

		return user.homeAccountId;
	}

	return null;
}

const errors = {
	AuthError: msal.AuthError,
	AuthErrorMessage: msal.AuthErrorMessage,
	BrowserAuthError: msal.BrowserAuthError,
	BrowserAuthErrorMessage: msal.BrowserAuthErrorMessage,
	BrowserConfigurationAuthError: msal.BrowserConfigurationAuthError,
	BrowserConfigurationAuthErrorMessage: msal.BrowserConfigurationAuthErrorMessage,
	InteractionRequiredAuthError: msal.InteractionRequiredAuthError,
};

const http = axios.create();

http.interceptors.request.use((config) => {
	return new Promise(async (resolve, reject) => {
		let request = JSON.parse(JSON.stringify(loginRequest));
		let token = null;

		if (config.url.indexOf('graph.microsoft.com') > -1) {
			// Add scopes for MS Graph
			request.scopes = [
				...graphScopes,
				...request.scopes
			];
		} else if (config.url.indexOf('onenote.com') > -1) {
			request.scopes = [
				...onenoteScopes,
			]
		} else {
			// Add scopes for environment
			request.scopes = [
				...request.scopes
			];
		}

		token = await getToken(request);

		if (token != null) {
			if (config.params == null) {
				config.params = {};
			}

			config.headers.common['Authorization'] = "Bearer " + token.accessToken;
		}

		// TODO: Reject promise if something goes wrong with fetching token
		resolve(config);
	})
}, function (error) {
	return Promise.reject(error);
});

http.interceptors.response.use((response) => {
	if (response.status == 204) {
		response.data = null;
	}

	return response;
});

// Export members of msal.js
export {
	loginRequest,
	interactiveRequest,
	getToken,
	http,
	getUser,
	setUser,
	getUserId,
	errors,
	onMsalTokenError,
	onMsalLoginRequired,
};

// Export MSAL instance
export default instance;