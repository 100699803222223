import React, { Component } from 'react';
import msal, { interactiveRequest, loginRequest, setUser } from 'libs/msal';
import { authentication, app } from '@microsoft/teams-js';

class AuthenticationPopup extends Component {

	getParameterByName = (name, url) => {
		if (!url) url = window.location.href;
		name = name.replace(/[\[\]]/g, '\\$&');
		var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';

		return decodeURIComponent(results[2].replace(/\+/g, ' '));
	}

	runLogin = () => {
		app.getContext().then((context) => {
			let request = {
				redirectUri: window.location.origin + '/login',
				loginHint: context.user.loginHint,
				...loginRequest
			};

			if (localStorage.getItem('dl-app-logged-out') != null) {
				request.prompt = 'select_account';
			}

			if (this.getParameterByName('interactive') != null) {
				request = {
					redirectUri: window.location.origin + '/login',
					loginHint: context.user.loginHint,
					...interactiveRequest
				};
			}

			if (this.getParameterByName('login_hint') != null) {
				request.loginHint = this.getParameterByName('login_hint');
			}

			msal.loginRedirect(request).catch((reason) => {
				authentication.notifyFailure(JSON.stringify(reason));
			});
		});
	}

	componentDidMount = () => {
		if (window.location.hash.indexOf('code') > -1) {
			msal.handleRedirectPromise().then((response) => {
				if (response != null) {
					setUser(response.account.username);
					authentication.notifySuccess(response);
				} else {
					if (msal.getAllAccounts().length > 0) {
						// Is logged in despite login failure
						let response = {
							account: msal.getAllAccounts()[0],
						};

						setUser(response.account.username);
						authentication.notifySuccess(response);

						return true;
					}

					authentication.notifyFailure('0');
				}
			}, (reason) => {
				console.log('reason', reason);
				authentication.notifyFailure(reason.errorCode);
			});

			return true;
		}

		this.runLogin();
	}

	render() {
		return(
			<div className="App-Container">
				Väntar på svar...
			</div>
		);
	}

}

export default AuthenticationPopup;