import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getMe } from 'actions/User';
import { teacher as skuidTeacher, student as skuidStudent } from 'constants/skuid'
import { modal as modalStyles } from 'constants/styles';
import Modal from 'react-modal';
import Button from 'UI/Button';

class SaveButtons extends Component {
	constructor(props) {
		super(props);

		this.state = {
			permissionError: true,
			isTeacher: null,
		};
	};

	isUserAllowed = (user) => {
		if (user != null) {
			if (user.assignedLicenses != null && user.assignedLicenses.length > 0) {
				const licenses = user.assignedLicenses;
				let isTeacher = false;

				const foundLicenses = licenses.map(license => {
					let isStudent = skuidStudent.find(function (skuid) {
						return skuid === license.skuId;
					});

					let isTeacher = skuidTeacher.find(function (skuid) {
						return skuid === license.skuId;
					});

					if (isStudent != null) {
						// License indicates the user is a student
						return false;
					}

					if (isTeacher != null) {
						// License indicates the user is a teacher
						return true;
					}

					return false;
				});

				isTeacher = foundLicenses.find(function (booleans) {
					return booleans === true;
				});

				if (isTeacher == null) {
					isTeacher = false;
				}

				this.setState({ isTeacher, permissionError: false });
			} else {
				this.setState({ permissionError: true });
			}
		}
	};

	componentDidMount = () => {
		if (this.props.me != null) {
			this.isUserAllowed(this.props.me);
		} else {
			this.props.getMe().then(() => {
				this.isUserAllowed(this.props.me);
			});
		}
	}

	permissionError = () => {
		return (
			<Modal
				onRequestClose={() => this.setState({ permissionError: false })}
				contentLabel=""
				shouldCloseOnOverlayClick
				isOpen={this.state.permissionError}
				style={modalStyles}
			>
				<div className="single-lesson">
					<div className="lesson-content" style={{ padding: '2rem 1rem', marginBottom: 0 }}>
						<div style={{ textAlign: 'center' }}>
							<h2 style={{ paddingBottom: '1rem' }}>Behörigheter kunde inte säkerställas!</h2>
						</div>

						<div className="App-Container">
							<p>
								Behörigheter kunde inte säkerställas. Testa logga ut och in från Teams.<br />
								Det kan ha flera orsaker:<br />
							</p>

							<ol>
								<li>Appen kunde inte hämta din användarinfo</li>
								<li>Appen har inte behörighet att läsa din licens hos Office 365</li>
								<li>Din session har löpt ut och appen kunde inte förnya den</li>
							</ol>
						</div>

						<div className="App-Container" style={{ padding: '1rem 0', marginTop: '1rem', textAlign: 'center' }}>
							<Button onClick={() => this.setState({ permissionError: false })}>
								Okej! Jag förstår
							</Button>
						</div>
					</div>
				</div>
			</Modal>
		);
	}

	render() {
		const { lesson, loading, entire } = this.props;
		const { isTeacher } = this.state;

		if (!isTeacher) {
			return null;
		}

		return (
			<Fragment>
				{this.permissionError()}

				<h4 className="sidebar-headline">
					Spara
				</h4>

				Kopiera lektionen till Klassanteckningsboken. Där kan du ändra och dela med dina kollegor.

				<div className="sidebar-save-lesson" style={{ marginBottom: '1rem' }}>
					<div className="save-item" onClick={this.props.onEntire}>
						<div className="save-item-content">
							<span>Spara hela lektionen till Klassanteckningsboken</span>
						</div>
					</div>
				</div>

				Kopiera och distribuera utvalt lektionsmaterial till Klassanteckningsboken

				<div className="sidebar-save-lesson">
					{lesson.lesson_parts.map(lesson_part => {
						return <div
							key={`save-item-${lesson_part.lesson_part}`}
							onClick={e => { this.props.onPart(lesson_part) }}
							className="save-item"
						>
							<div className="save-item-content">
								Lektionsdel {lesson_part.lesson_part}<br />
								<span>{lesson_part.header}</span>
							</div>
						</div>
					})}
				</div>

				{loading.length > 0 ?
					<div className="loading-overlay">
						{loading.map(lpart => {
							return <span key={`save-indicator-${lpart.lesson_part}`}>
								Sparar lektionsdel {lpart.lesson_part}: {lpart.header}...
							</span>;
						})}
					</div>
					: null}

				{entire ?
					<div className="loading-overlay">
						<span>Sparar uppgift till Klassanteckningsboken...</span>
					</div>
					: null}
			</Fragment>
		);
	}
}

function mapStateToProps(state) {
	return {
		me: state.User.me,
	};
};

export default connect(mapStateToProps, {
	getMe,
})(SaveButtons);
