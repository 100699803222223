import { combineReducers } from 'redux';

import User from './User';
import Meta from './Meta';
import Lessons from './Lessons';

export default combineReducers({
	User,
	Meta,
	Lessons,
});